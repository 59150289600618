import React, { Component, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AuthRoute from "./views/tools/AuthRoute";
import {firstSpinner} from "./UsefulConstants";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import './scss/style.scss';

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Login2FA = React.lazy(() => import('./views/pages/login/Login2Fa'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Suspense fallback={firstSpinner()}>
          <Routes>
            <Route exact path="/sign-in" name="Login Page" element={<Login />} />
            <Route exact path="/sign-in-2fa" name="Login 2FA Page" element={<Login2FA />} />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route path="*" name="Home" element={<AuthRoute><DefaultLayout /></AuthRoute>}/>
          </Routes>
        </Suspense>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
      </BrowserRouter>
    )
  }
}

export default App
